<template>
  <div class='authority'>
    <div class="buttons">
      <el-button type="danger" @click="addRoleVisible = true">新增角色</el-button>
      <el-button type="success" @click="$router.push('/allot')">权限分配</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" border size="small">
      <el-table-column prop="name" label="角色名称">
      </el-table-column>
      <el-table-column prop="value" label="角色简写">
      </el-table-column>
      <el-table-column prop="gmtcreate" label="角色创建时间">
        <template slot-scope="{row}">
          {{$moment(row.gmtcreate).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
      </el-table-column>
      <el-table-column prop="action" label="操作">
        <template slot-scope="{row}">
          <el-popover placement="bottom-start" width="180" v-model="row.visible">
            <p>您确认要删除该角色吗？</p>
            <div style="text-align: right; margin: 10px 0 0 0">
              <el-button size="mini" type="text" @click="row.visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="ensureDelete(row),row.visible = false">确定</el-button>
            </div>
            <el-button type="text" slot="reference" style="color:red">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
    <!-- 新增角色弹框 -->
    <el-dialog title="添加角色" :visible.sync="addRoleVisible" width="30%" :lock-scroll="false">
      <el-form :model="roleForm" :rules="rules" ref="roleForm">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="roleForm.name" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色简写" prop="value">
          <el-input v-model="roleForm.value" placeholder="请输入角色名称英文简写"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addRoleVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRole" :loading="roleLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRoleList, addRole, deleteRole } from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      totalList: [],
      total: 0,
      tableData: [],
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      // 添加角色弹框
      addRoleVisible: false,
      roleForm: {
        name: '',
        value: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ],
        value: [
          { required: true, message: '请输入角色简写', trigger: 'blur' },
          {
            pattern: /^[a-zA-Z]+$/,
            message: "只能输入大小写字母",
            trigger: "blur",
          }

        ]
      },
      roleLoading: false
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['token', 'realname'])
  },
  methods: {
    // 获取权限列表
    async init () {
      const res = await getRoleList()
      this.totalList = res
      this.total = res.length
      this.getList()
      // console.log('res :>> ', this.totalList, this.total);
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    // 新增角色
    submitRole () {
      this.$refs.roleForm.validate(async (valid) => {
        if (valid) {
          this.roleLoading = true
          await addRole(this.roleForm)
          this.$message.success('新增角色成功')
          await this.$refs.roleForm.resetFields();
          this.queryParams.pageSize = 10
          this.queryParams.pageNum = 1
          this.addRoleVisible = false
          this.roleLoading = false
          this.$add(
            {
              userName: this.realname,
              module: '权限管理',
              content: `${this.realname}添加了${this.roleForm.name}角色，角色简写为${this.roleForm.value}`,
              type: 3,
              platform: 8,
              operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
            }
          )
          this.init()
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    // 删除角色
    async ensureDelete (val) {
      await deleteRole({ roleId: val.id })
      this.$message.success('删除角色成功')
      this.$add(
        {
          userName: this.realname,
          module: '权限管理',
          content: `${this.realname}删除了${val.name}角色`,
          type: 4,
          platform: 8,
          operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
        }
      )
      this.init()
      // console.log('val :>> ', val);

    },
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    },
  }
};
</script>

<style scoped lang="scss">
.authority {
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
</style>
